import * as React from "react"
import Layout from "../components/Layout.js"
import MapBlock from "../components/MapBlock.js"

const ContactsPage = () => {
  return (
    <Layout>
      <title>Контакты</title>
      <MapBlock isFullHeight/>
    </Layout>
  )
}

export default ContactsPage
